import { useEffect } from 'react';

import { fetchPosts } from 'api/services/posts/requests';

import { useAppDispatch, useAppSelector } from 'features/hooks/reduxHooks';

import { PostCard } from './components/PostCard';
import { SPostUserWrapper } from './style';

const PostsUser = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.posts.data);

  const lastTenPosts = data.slice(Math.max(data.length - 10, 1));

  useEffect(() => {
    dispatch(fetchPosts());
  }, []);

  return (
    <SPostUserWrapper>
      {lastTenPosts.map((post, i) => <PostCard key={i} img={post?.imgUri} time={post?.createdAt} text={post.text} />)}
    </SPostUserWrapper>
  );
};

export default PostsUser;
