import { FC } from 'react';

import { Box } from '@mui/material';

import ClippedDrawer from './ClippedDrawer/ClippedDrawer';
import SwipeableTemporaryDrawer from './SwipeableTemporaryDrawer/SwipeableTemporaryDrawer';

import { ISideBarProps } from './types';
import { SClippedDrawerWrapper, SSwipeableDrawerWrapper } from './style';

const SideBar: FC<ISideBarProps> = ({ state, toggleDrawer }) => {
  return (
    <>
      <Box sx={SClippedDrawerWrapper}>
        <ClippedDrawer />
      </Box>
      <Box sx={SSwipeableDrawerWrapper}>
        <SwipeableTemporaryDrawer
          state={state}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    </>
  );
};

export default SideBar;
