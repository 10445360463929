export const SUserMenu = { 
  width: { xs: '280px', md: '320px' } 
};

export const SUserMenuIcon = {
  fontSize: '1.3rem',
};

export const SUserMenuList = {
  width: '100%',
  maxWidth: 350,
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  minWidth: { xs: '100%', md: 300 },
  '& .MuiListItemButton-root': {
    mt: 0.5
  }
};

export const SUserMenuButton = {
  borderRadius: '10px',
  '&: hover': {
    background: '#ede7f6',
    color: '#5e35b1e8',
    [`& .MuiListItemIcon-root`]: {
      color: "#5e35b1e8"
    }
  },
};
