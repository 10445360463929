import { IRoute } from 'router/types';
import ViewComponent from 'views/ViewComponent';
import { DashboardPageView } from './constants';
import Dashboard from 'components/Dashboard';

export const routes: IRoute = {
  path: `/${DashboardPageView}/*`,
  component: ViewComponent,
  defaultModule: {
    component: Dashboard,
    module: DashboardPageView,
    index: true,
  },
  modules: [
    {
      path: `*`,
      component: Dashboard,
    },
  ],
};
