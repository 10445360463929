import React, { FC, ForwardedRef } from 'react';
import { Dialog, Zoom, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IPostDialogProps, ITransitionProps } from './types';
import { SImage, SCloseButton } from './styles';

const Transition = React.forwardRef(function Transition(props: ITransitionProps, ref: ForwardedRef<unknown>) {
  return <Zoom timeout={500} ref={ref} {...props} />;
});

const PostDialog: FC<IPostDialogProps> = ({ open, handleClose, imageUrl }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
     
      <Button 
        onClick={handleClose} 
        sx={SCloseButton}
      >
        <CloseIcon/>
      </Button>
      
      <SImage src={imageUrl} alt="postImage" />
    </Dialog>
  );
};

export default PostDialog;
