import axios from 'axios';

import { IHttpClientConfig, IHttpClient } from './types';

export const createHttpClient = (config: IHttpClientConfig): IHttpClient => {
  const axiosInstance = axios.create(config);

  const httpClient = {
    request: axiosInstance.interceptors.request,
    response: axiosInstance.interceptors.response,
    get: axiosInstance.get,
    delete: axiosInstance.delete,
    head: axiosInstance.head,
    options: axiosInstance.options,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
  };
  
  return httpClient;
};
