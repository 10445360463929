import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';

export const icons = {
  DashboardOutlinedIcon,
  TableChartOutlinedIcon,
  LocalOfferOutlinedIcon,
  PushPinIcon,
};
