import { FC, MouseEvent } from 'react';

import { visuallyHidden } from '@mui/utils';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { IEnhancedTableHeadProps } from './types';

import { STableCell } from './style';

const EnhancedTableHead: FC<IEnhancedTableHeadProps> = ({ 
  numSelected, 
  onRequestSort, 
  onSelectAllClick, 
  headCells, 
  order, 
  orderBy, 
  rowCount, 
  comparator 
}) => {
  
  const { t } = useTranslation();

  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all posts',
            }}
          />
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            sx={STableCell}
            key={i}
            align={i !== 0 ? 'center' : 'left'}
            padding={'normal'}
          >
            {comparator === headCell ?
              <TableSortLabel
                active={orderBy === headCell}
                direction={orderBy === headCell ? order : 'asc'}
                onClick={createSortHandler(headCell)}
              >
                {t(`table.header.${headCell}`)}
  
                {orderBy === headCell ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              :
              <>
                {t(`table.header.${headCell}`)}
              </>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
