import { FC } from 'react';
import { 
  Box, 
  Checkbox, 
  TableCell, 
  TableRow, 
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import Chips from 'components/Chips';

import { managePostsCell } from '../EnhancedTable/utils/ManagePostsCell';

import { SImageBox, STableCell } from '../EnhancedTable/style';
import { IPostsTableBody } from './types';
import { SPostCell } from './styles';

const PostsTableBody: FC<IPostsTableBody> = (
  { 
    handleClick, 
    handleClickOpenModal, 
    gradeHandler,
    initialValues, 
    isItemSelected, 
    labelId, 
    row 
  }
) => {

  const { t } = useTranslation();

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, row.uid as string)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.uid}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>

      {initialValues
        .map((value, i) => {
          return (
            <TableCell sx={STableCell} align='center' key={i}>
              {value && value === row.imgUri
                ? <Box
                  onClick={(e) => handleClickOpenModal(e, value)}
                  component="img"
                  sx={SImageBox}
                  alt="Your image was there("
                  src={value}
                />
                : (!value && typeof value !== 'number') || (typeof value === 'object' && !value['length'])
                  ? <Chips color='warning' label={t('table.body.empty')}/> 
                  :  
                  <Box sx={SPostCell}>
                    {managePostsCell(value, row, gradeHandler)}
                  </Box>
              }
            </TableCell>
          );
        })}
    </TableRow>
  );
};

export default PostsTableBody;
