import { 
  useState, 
  ChangeEvent, 
  MouseEvent, 
  ReactNode 
} from "react";
import { Box } from "@mui/material";

import { postsSchema } from "components/Posts/schema";
import { markersSchema } from "components/Markers/schema";
import { IPost } from "api/services/posts/types";
import { IMarkers } from "api/services/markers/types";
import { TOrder } from '../types';

import { SBoxGradeGreen, SBoxGradeRed, SBoxGradeYellow } from "../style";

export const useEnhancedTable = (data: IPost[] | IMarkers[]) => {
  const [order, setOrder] = useState<TOrder>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [open, setOpen] = useState<boolean>(false);
  const [imgUri, setImgUri] = useState<string>('');

  const createHeadCells = (data: IPost[] | IMarkers[]): string[] => {
    const res = [];
    
    if (data) {
      const [initialObject] = data;

      for (let property in initialObject) {
        res.push(property);
      };
      
      initialObject?.uid ?
        res.sort((a, b) => postsSchema[a as keyof typeof postsSchema] - postsSchema[b as keyof typeof postsSchema])
        :
        res.sort((a, b) => markersSchema[a as keyof typeof markersSchema] - markersSchema[b as keyof typeof markersSchema]);
        
      return res;
    }

    return [];
  };
  const headCells = createHeadCells(data);
  
  const checkAndSortRows = (
    row: IPost | IMarkers, 
    schema: typeof postsSchema | typeof markersSchema
  ): string[] => {
    const initialRow = Object.keys(schema);
    const checkingRow = Object.keys(row);

    for(let i = 0; i < initialRow.length; i ++) {
      if(!checkingRow.includes(initialRow[i])) {
        checkingRow.push(initialRow[i]);
      }
    }

    const result = checkingRow
      .sort((a, b) => schema[a as keyof typeof schema] - schema[b as keyof typeof schema])
      .reduce(
        (obj: any, key) => {
          obj[key] = row[key as keyof typeof schema];
          return obj;
        },
        {}
      );

    return result;
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {  
    if (event.target.checked) {
      const newSelected = data.map((n: IPost | IMarkers) => {
        if(Object.keys(n).find(e => e === 'uid') === 'uid') {
          return n.uid;
        };

        return n.id;
      });
      setSelected(newSelected as string[]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleClickOpenModal = (e: MouseEvent<unknown>, img: string) => {
    e.stopPropagation();
    setOpen(true);
    setImgUri(img);
  };
  
  const handleCloseModal = () => {
    setOpen(false);
  };
  
  const gradeHandler = (grade: number): ReactNode => {
    switch (grade) {
    case 1:
      return <Box sx={SBoxGradeGreen} />;
    case 0:
      return <Box sx={SBoxGradeYellow} />;
    case -1:
      return <Box sx={SBoxGradeRed} />;
    default:
      return;
    }
  };

  return [
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    headCells,
    checkAndSortRows,
    handleSelectAllClick,
    handleClick,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    open,
    imgUri,
    handleClickOpenModal,
    handleCloseModal,
    gradeHandler
  ] as const;
};
