import React, { ForwardedRef, useState } from 'react';
import { Dialog, Zoom, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IPostTimelineProps, ITransitionProps } from './types';
import { SImage, SCloseButton } from './styles';
import { useAppDispatch } from 'features/hooks/reduxHooks';
import { setPostImageUrl } from 'features/postSlice';

const Transition = React.forwardRef(function Transition(props: ITransitionProps, ref: ForwardedRef<unknown>) {
  return <Zoom timeout={500} ref={ref} {...props} />;
});

export const PostTimelineDialog = ({ postImageUrl }: IPostTimelineProps) => {
  const dispatch = useAppDispatch();
  
  const [open, setOpen] = useState<boolean> (true);

  const handleClose = () => {
    dispatch(setPostImageUrl(null));
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
     
      <Button 
        onClick={handleClose} 
        sx={SCloseButton}
      >
        <CloseIcon/>
      </Button>
      
      <SImage src={postImageUrl} alt="postImage" />
    </Dialog>
  );
};
