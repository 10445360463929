export const SPostsTableWrapper = {
  width: '100%',
};
  
export const SPaper = {
  width: '100%', 
  mx: 0,
  mb: 2
};
  
export const STable = {
  minWidth: 350
};

export const STableCell = {
  py: '2px',
  lineHeight: 1.2,
};
    
export const SImageBox = {
  height: 50, 
  width: 50, 
  cursor: 'pointer'
};

export const SBoxGradeGreen = {
  width: '30px', 
  height: '30px', 
  backgroundColor: '#2E7332', 
  borderRadius: '50%'
};

export const SBoxGradeYellow = {
  width: '30px', 
  height: '30px', 
  backgroundColor: 'yellow', 
  borderRadius: '50%'
};

export const SBoxGradeRed = {
  width: '30px', 
  height: '30px', 
  backgroundColor: 'red', 
  borderRadius: '50%'
};
