import { FC } from 'react';
import { Box } from '@mui/material';

import { useAppSelector } from 'features/hooks/reduxHooks';

import EnhancedTable from 'components/Posts/PostsAdmin/components/EnhancedTable';

import { IMarkers } from 'api/services/markers/types';
import { MarkersTableComparator, MarkersTableInitializer } from './constants';

import { SMarkersContainer } from './styles';

const Markers: FC = () => {
  const data = useAppSelector(state => state.markers.data);
  
  if(data === "") {
    return <h1>Loading...</h1>;
  }

  if(data === "error") {
    return <h1>Error</h1>;
  }

  return (
    <Box sx={SMarkersContainer}>
      <EnhancedTable 
        data={data as IMarkers[]}
        tableTitle={MarkersTableInitializer}
        tableHeadComparator={MarkersTableComparator}
        tableType={MarkersTableInitializer}
      /> 
    </Box>
  );
};

export default Markers;
