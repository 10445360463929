import { FC } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';

import { dateHandler } from 'components/Posts/PostsAdmin/components/EnhancedTable/utils/dateHandler';

import { IMarkersTableBody } from './types';

import { SMarkersTableCell } from './styles';

const MarkersTableBody: FC<IMarkersTableBody> = (
  { 
    handleClick, 
    initialValues, 
    isItemSelected, 
    labelId, 
    row 
  }
) => {
  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, row.id as string)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>

      {initialValues.map((value, i) => {
        return (
          <TableCell sx={SMarkersTableCell} align='center' key={i}>
            {typeof value === 'number' && value === row.createdAt ? dateHandler(value) : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MarkersTableBody;
