import { FC } from 'react';
import { Box } from '@mui/material';
import { SLogoNameImage } from './styles';

const LogoName: FC = () => {
  return (
    <Box
      component="img"
      alt="logoname"
      src="https://svitlofour.com/img/svitloforName.svg"
      sx={SLogoNameImage}
    />
  );
};

export default LogoName;
