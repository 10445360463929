import { createSlice } from '@reduxjs/toolkit';
import { fetchMarkers } from 'api/services/markers/requests';
import { IMarkersState } from './types';

const initialState: IMarkersState = {
  data: "",
};

export const markersSlice = createSlice({
  name: "markers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMarkers.pending, (state) => {
      state.data = '';
    });

    builder.addCase(fetchMarkers.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    builder.addCase(fetchMarkers.rejected, (state) => {
      state.data = 'error';
    });
  },
});

export default markersSlice.reducer;
