import { FC } from 'react';

import { useAppSelector } from 'features/hooks/reduxHooks';

import PostsAdmin from './PostsAdmin';
import PostsUser from './PostsUser';

const Posts: FC = () => {
  const role = useAppSelector(state => state.signup.role);

  return role === 'user' ? <PostsUser /> : <PostsAdmin />;
};

export default Posts;
