import { FC } from 'react';

import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

import { IEnhancedTableToolbarProps } from "./types";

import { SToolbarWrapper, STypography } from './style';

const EnhancedTableToolbar: FC<IEnhancedTableToolbarProps> = ({ numSelected, title }) => {
  
  const { t } = useTranslation();
  
  return (
    <Toolbar sx={SToolbarWrapper}>
      {numSelected > 0 ? (
        <Typography
          sx={STypography}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {t('table.toolbar.selected')}
        </Typography>
      ) : (
        <Typography
          sx={STypography}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t(`table.title.${title}`)}
        </Typography>
      )}
      
      {numSelected > 0 ? (
        <Tooltip title={t('table.toolbar.delete')}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('table.toolbar.filter')}>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
