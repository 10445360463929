export const SLogoWrapperBox = {
  width: { 
    xs: 40, 
    md: 228
  }
};
  
export const SLogoBox = { 
  display: { 
    xs: 'none', 
    md: 'block' 
  }, 
  flexGrow: 1 
};
  
export const SButtonBase = {
  borderRadius: '12px', 
  overflow: 'hidden', 
  display: { 
    xs: 'block', 
    md: 'none' 
  } 
};
  
export const SAvatar = {
  transition: 'all .2s ease-in-out',
  background: "#ede7f6",
  color: "#5e35b1",
  '&:hover': {
    background: "#5e35b1",
    color: "#ede7f6"
  }
};
  
export const SMenuIcon = {
  fontSize: "1.5rem"
};
  
export const SEmptyBox = {
  flexGrow: 1
};
