export const STimelineOppositeContent = { m: 'auto 0' };

export const STimelineContent = { 
  py: '12px', 
  px: 2 
};

export const SImageBox = {
  height: 50, 
  width: 50, 
  borderRadius: '50%',
  cursor: 'pointer', 
  margin: '5px'
};
