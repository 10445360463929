import { IRoute } from 'router/types';
import ViewComponent from 'views/ViewComponent';
import { PostsPageView } from './constants';
import Posts from 'components/Posts';

export const routes: IRoute = {
  path: `/${PostsPageView}/*`,
  component: ViewComponent,
  defaultModule: {
    component: Posts,
    module: PostsPageView,
    index: true,
  },
  modules: [
    {
      path: `*`,
      component: Posts,
    },
  ],
};
