import { routes as dashboardRoutes } from './dashboard';
import { routes as notFoundRoutes } from './404';
// import { routes as loginRoutes } from './loginPage';
import { routes as pricesRoutes } from './prices';
import { routes as postsRoutes } from './posts';
import { routes as markersRoutes } from './markers';

export const routes = [
  dashboardRoutes,
  // loginRoutes,
  pricesRoutes,
  postsRoutes,
  markersRoutes,
  notFoundRoutes,
];
