export const SBox = { 
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const SColorCircle = { 
  display: 'flex',
  minWidth: 150, 
  minHeight: 150,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '4px 2px 8px black'
};

export const SColorCount = { 
  fontSize: '2rem', 
  color: '#ede7f6',
  textShadow: '4px 2px 8px black',
  fontWeight: 600
};

export const SColorTitle = {
  fontSize: '1rem',
  fontWeight: 800
};
