import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Box,
  Popper,
  ClickAwayListener,
  Paper,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import Transitions from 'shared/Transition';

import { useAppSelector } from 'features/hooks/reduxHooks';

import { 
  SUserMenu,
  SUserMenuIcon,
  SUserMenuList,
  SUserMenuButton,
} from './style';

const ProfilePopper: FC<any> = ({ open, setOpen, anchorRef }) => {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();
  const role = useAppSelector(state => state.signup.role);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 14]
            }
          }
        ],
        placement: 'bottom'
      }}
    >
      {({ TransitionProps }) => (
        <Transitions in={open} type="grow" position='top-right' {...TransitionProps}>
          <Paper sx={SUserMenu}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Card elevation={6}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5">{`Your status is: ${role}`}</Typography>
                  <Divider style={{ marginTop: 20 }}/>
                </Box>
                <Box sx={{ p: 1 }}>
                  <List
                    component="nav"
                    sx={SUserMenuList}
                  >
                    <ListItemButton
                      sx={SUserMenuButton}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon sx={SUserMenuIcon} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        signOut();
                        navigate('/');
                      }}
                      sx={SUserMenuButton}
                    >
                      <ListItemIcon>
                        <LogoutOutlinedIcon sx={SUserMenuIcon} />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                    </ListItemButton>
                  </List>
                </Box>
              </Card>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  );
};

export default ProfilePopper;
