import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { IRouteComponentProps } from '../../router/types';

import View from '../View';

const ViewComponent: FC<IRouteComponentProps> = ({ modules, defaultModule, view }) => {
  return (
    <View>
      <Routes>
        {modules.map(
          (
            { path, to, component: Component, module, exactLink, redirectPath, ...rest },
            index,
          ) => {
            return (
              <Route
                key={`${path}-${index}`}
                path={`${path}`}
                index={exactLink}
                // {...rest}
                element={<Component {...rest} />}
              />
            );
          },
        )}
      </Routes>
    </View>
  );
};

export default ViewComponent;
