import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import Header from 'components/Auth/CognitoAuth/Header';

import './App.css';
import store from './store';
import Router  from './router';

const App = () => {
  return (
    <Provider store={store}>
      <Authenticator
        loginMechanisms={['email']}
        components={{
          Header(): ReactElement {
            return <Header />;
          },
        }}
      >
        <Router />
      </Authenticator>
    </Provider>
  );
};

export default App;
