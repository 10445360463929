export const SPostAmountBox = { 
  p: 2.25, 
  backgroundColor:' #f8ceec',
  backgroundImage: 'linear-gradient(315deg, #f8ceec 0%, #a88beb 74%)',
  borderRadius: '10px',
  // with: '100%',
  // width: 1/2,
};

export const SPostAmount = { 
  fontSize: '4.125rem', 
  color: '#ede7f6',
  textShadow: '4px 2px 8px #5e35b1',
  fontWeight: 800, 
  mr: 1, 
  mt: 1.75, 
  mb: 0.75 
};

export const SGridTotal = { mb: 1.25 };

export const STotalPosts = {
  fontSize: '2rem',
  fontWeight: 600,
  color: '#ede7f6'
};
