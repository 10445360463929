import { Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { colorCounter } from 'components/Dashboard/utils/colorCounter';
import { useAppSelector } from 'features/hooks/reduxHooks';
import { ColorCircle } from '../ColorCircle';

export const PostColorCircles  = () => {

  const data = useAppSelector(state => state.posts.data);

  const color = { 
    green: 'green', 
    yellow: 'yellow', 
    red: 'red' 
  };
  
  const green = colorCounter(data).green;
  const yellow = colorCounter(data).yellow;
  const red = colorCounter(data).red;

  const { t } = useTranslation();

  return (
    <> 
      <Stack 
        justifyContent={'center'}
        direction={'row'} 
        spacing={{ xs: 1, sm: 2, md: 4 }} 
      >
        <ColorCircle count={green} color={color.green} title={t(`dashboard.circles.${color.green}`)} />
        <ColorCircle count={yellow} color={color.yellow} title={t(`dashboard.circles.${color.yellow}`)} />
        <ColorCircle count={red} color={color.red} title={t(`dashboard.circles.${color.red}`)} />
      </Stack>
    </>
  );
};
