import { FC } from 'react';

import { Box, Container, Typography } from "@mui/material";

import { SFooterBox } from './styles';

const Footer: FC = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={SFooterBox}>
        <Typography variant="caption" color="initial">
              Copyright ©2023. Svitlofour Limited
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
