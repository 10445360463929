import { combineReducers } from '@reduxjs/toolkit';
import signupReducer from '../features/signupSlice';
import postReducer from '../features/postSlice';
import markersReducer from '../features/markersSlice';

const rootReducer = combineReducers({
  signup: signupReducer,
  posts: postReducer,
  markers: markersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
