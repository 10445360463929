import { IPost } from "api/services/posts/types";
import { TColorMarks } from "./types";

export const colorCounter = (data: IPost[]) => {
  return data.reduce<TColorMarks>((acc, post) => {
    post.grade === 1 
      ? acc['green'] += 1 
      : post.grade === -1 
        ? acc['red'] += 1 
        : acc['yellow'] += 1;
    return acc;
  }, { red: 0, yellow: 0, green: 0 });
};
