import { FC } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@mui/material";

import { useTranslation } from 'react-i18next';

import EnhancedTableHead from "components/Posts/PostsAdmin/components/EnhancedTable/components/EnhancedTableHead";
import EnhancedTableToolbar from "components/Posts/PostsAdmin/components/EnhancedTable/components/EnhancedTableToolbar";
import PostDialog from "components/Posts/PostDialog";
import PostsTableBody from "components/Posts/PostsAdmin/components/PostsTableBody";
import MarkersTableBody from "components/Markers/MrakersTableBody";
import NotFound from "components/NotFound";

import { getComparator, stableSort } from "./utils/sortHandlers";
import { useEnhancedTable } from "./hooks/useEnhancedTable";

import { MarkersTableInitializer } from "components/Markers/constants";

import { markersSchema } from "components/Markers/schema";
import { postsSchema } from "components/Posts/schema";

import { IEnhancedTableProps } from "./types";
import { IMarkers } from "api/services/markers/types";
import { IPost } from "api/services/posts/types";

import { PostsTableInitializer } from '../../constants';

import { SPaper, SPostsTableWrapper, STable } from "./style";

const EnhancedTable: FC<IEnhancedTableProps> = (
  {
    data,
    tableTitle,
    tableHeadComparator,
    tableType,
  }) => {

  const { t } = useTranslation();

  const [
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    headCells,
    checkAndSortRows,
    handleSelectAllClick,
    handleClick,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    open,
    imgUri,
    handleClickOpenModal,
    handleCloseModal,
    gradeHandler
  ] = useEnhancedTable(data as IPost[] | IMarkers[]);

  return (
    <Box sx={SPostsTableWrapper}>
      <Paper sx={SPaper}>
        <EnhancedTableToolbar
          title={tableTitle}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={STable}
            aria-labelledby={t(`table.title.${tableTitle}`)}
            size={'small'}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data?.length}
              orderBy={orderBy}
              order={order}
              comparator={tableHeadComparator}
            />

            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const schema = row.uid ? postsSchema : markersSchema;
                    
                  const orderedRow = checkAndSortRows(row, schema);
                    
                  const initialValues = Object.values(orderedRow);
                  const isItemSelected = row?.uid ? isSelected(row.uid as string) : isSelected(row.id as string);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (tableType === PostsTableInitializer) {
                    return (
                      <PostsTableBody
                        handleClickOpenModal={handleClickOpenModal}
                        gradeHandler={gradeHandler}
                        handleClick={handleClick}
                        initialValues={initialValues}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        row={row}
                        key={row.uid}
                      />
                    );
                  }

                  if (tableType === MarkersTableInitializer) {
                    return (
                      <MarkersTableBody
                        handleClick={handleClick}
                        initialValues={initialValues}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        row={row}
                        key={row.id}
                      />
                    );
                  }

                  return <NotFound key={index} />;
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: (33) * emptyRows, }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('table.pagination.rowsperpage')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <PostDialog
        open={open}
        handleClose={handleCloseModal}
        imageUrl={imgUri}
      />
    </Box>
  );
};

export default EnhancedTable;
