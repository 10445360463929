export const SClippedDrawerWrapper = {
  display: { xs: 'none', md: 'block' }
};

export const SSwipeableDrawerWrapper = {
  display: { xs: 'block', md: 'none' }
};

export const SUnactiveButton = {
  borderRadius: '10px',
  mx: '2px',
  color: "grey",
  '&:hover': {
    background: "#ede7f6",
    color: "#5e35b1e8",
    [`& .MuiListItemIcon-root`]: {
      color: "#5e35b1e8"
    }
  },
};

export const SActiveButton = {
  borderRadius: '10px',
  mx: '2px',
  background: "#ede7f6",
  color: "#5e35b1e8",
  [`& .MuiListItemIcon-root`]: {
    color: "#5e35b1e8"
  }
};

export const SListSubheader = {
  fontWeight: 600, 
  color: 'rgb(33, 33, 33)', 
  fontSize: '0.875rem'
};
  
export const SNavLink = {
  textDecoration: 'none', 
  width: '100%'
};

export const STypography = {
  my: 'auto'
};
