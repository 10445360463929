import styled from 'styled-components';

export const SCloseButton = {
  position: 'absolute',
  color: '#ede7f6',
  top: '6px',
  right: '2px',
  transition: 'all 100ms ease-in-out',

  '&:hover' : {
    color: '#fff'
  }
};

export const SImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
