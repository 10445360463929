import { FC } from 'react';
import Logo from './Logo/Logo';
import LogoName from './LogoName/LogoName';

import { ButtonBase } from '@mui/material';

const LogoSection: FC = () => {
  return (
    <ButtonBase disableRipple>
      <Logo />
      <LogoName/>
    </ButtonBase>
  );
};

export default LogoSection;
