import { Box, Typography } from '@mui/material';

const Header = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h3>Welcome</h3>
      <Typography sx={{ alignItems: 'center' }}>to</Typography>
      <h1>SvitloFour</h1>
    </Box>
  );
};

export default Header;
