import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpClient } from 'api/clients';
import { IPost } from './types';

export const fetchPosts = createAsyncThunk<IPost[], undefined>(
  'posts/fetchPosts',
  async () => {
    const response = await httpClient.get('posts');

    return response.data;
  }
);
