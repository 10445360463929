import { FC } from 'react';
import { Box } from '@mui/material';
import { SLogoImage } from './styles';

const Logo: FC = () => {
  return (
    <Box
      component="img"
      src="https://svitlofour.com/img/icon.svg"
      alt="logo"
      sx={SLogoImage}
    />
  );
};

export default Logo;
