export const SMarkersContainer = {
  mt: { xs: '10px', md: '1px' },
  mx: { xs: 'auto' },
  mb: { xs: '60px', md: '1px' },
  p: 0,
  paddingBottom: { xs: '20px', md: '60px' },
  width: { xs: '94.5%', md: 'calc(100vw - 304px)' },
  height: '100%',
  display: "flex",
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
