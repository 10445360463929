import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-central-1:de12c2c8-8a26-41a1-84e7-713790e71a78',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_1DPnBbU16',
    userPoolWebClientId: '3s85r96cug1ck13t1lf6uq2oc3',
  },
});

// You can get the current config object
const currentConfig = Auth.configure();

export default currentConfig;
