import { IRoute } from 'router/types';
import ViewComponent from 'views/ViewComponent';
import NotFound from 'components/NotFound';

export const routes: IRoute = {
  path: '*',
  component: ViewComponent,
  view: '*',
  defaultModule: {
    path: '*',
    index: true,
    component: NotFound,
  },
  modules: [{
    path: '*',
    component: NotFound,
  },],
};
