import { IRoute } from 'router/types';
import ViewComponent from 'views/ViewComponent';
import { PricesPageView } from './constants';
import Prices from 'components/Prices';

export const routes: IRoute = {
  path: `/${PricesPageView}/*`,
  component: ViewComponent,
  defaultModule: {
    component: Prices,
    module: PricesPageView,
    index: true,
  },
  modules: [
    {
      path: `*`,
      component: Prices,
    },
  ],
};
