export const SLayoutWrapper = {
  display: 'flex', 
  flexDirection: 'column'
};

export const SAppbarWrapper = {
  display: 'flex' 
};

export const SToolbar = {
  padding: { xs: '8px 10px', md: '8px' }
};

export const LayoutBoxStyle = {
  position: 'relative',
  top: '80px',
  ml: { xs: 0, md: 0 },
  mr: { xs: 0, md: 2 },
  p: { xs: 0, md: 2 },
  borderRadius: '10px',
  width: '100%',
  height: '100%',
  backgroundColor: '#e2bbff45',
};
