import { FC } from 'react';
import Footer from 'components/Footer/Footer';
import { Paper } from '@mui/material';
import { SFooterPaper } from './styles';

const FooterView: FC = () => {
  return (
    <Paper component="footer" sx={SFooterPaper} square variant="outlined">
      <Footer/>
    </Paper>
  );
};

export default FooterView;
