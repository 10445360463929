export const SClippedDrawerWrapper = {
  display: 'flex'
};

export const SBox = {
  overflow: 'auto', 
  px: '10px'
};

export const SClippedDrawer = {
  width: 251,
  flexShrink: 0,
  position: 'sticky',
  [`& .MuiDrawer-paper`]: {
    width: 251,
    boxSizing: 'border-box',
    top: '82px',
    border: 'none',
    height: 'calc(100vh - 82px)',
  }
};
