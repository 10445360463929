import { useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuthenticator } from "@aws-amplify/ui-react";

import { setCurrentRole } from 'features/signupSlice';
import { useAppDispatch } from 'features/hooks/reduxHooks';

import { renderViewRoutes } from './renderViewRoutes';
import Layout from '../shared/Layout';

const Router = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pathname = window.location.pathname;

  const { user, route } = useAuthenticator();

  useEffect(() => {
    if(pathname === '/' && route === 'authenticated') {
      navigate('/dashboard');
    }

    dispatch(setCurrentRole(user.attributes?.['custom:role']));
  }, []);

  return (
    <Routes>
      {route === 'authenticated' ? (
        <Route element={<Layout />}>
          {renderViewRoutes()}
        </Route>
      ) : (
        <Route element={<Navigate to="/" />} />
      )
      }
    </Routes>
  );
};

export default Router;
