import { FC, useRef, useState } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from '@mui/material';

import ProfilePopper from './components/ProfilePopper';
import { SButton } from './styles';

const ProfileSection: FC = () => {
  const { user } = useAuthenticator();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <SButton onClick={() => setOpen((prevOpen) => !prevOpen)} ref={anchorRef}>
        {user.attributes?.email}
      </SButton>
      <ProfilePopper open={open} setOpen={setOpen} anchorRef={anchorRef} />
    </Box>
  );
};

export default ProfileSection;
