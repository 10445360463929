import { FC } from "react";

import { Box, Grid, Typography }  from "@mui/material";

import { useTranslation } from "react-i18next";

import { IPostAmountCard } from './types';
import {
  SGridTotal, 
  SPostAmount, 
  SPostAmountBox, 
  STotalPosts 
} from './style';

export const PostAmountCard: FC<IPostAmountCard> = ({ totalPosts }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={SPostAmountBox}>
      <Grid container direction="column">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Typography sx={SPostAmount}>
                {totalPosts}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={SGridTotal}>
          <Typography
            sx={STotalPosts}
          >
            {t('dashboard.card.totalpost')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
