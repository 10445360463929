import { FC, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'features/hooks/reduxHooks';

import { fetchPosts } from 'api/services/posts/requests';

import Loader from 'shared/components/Loader';

import { PostTimeline } from './components/PostTimeline';
import { PostTimelineDialog } from './components/PostTimelineDialog';
import { PostAmountCard } from './components/PostAmountCard';
import { PostColorCircles } from './components/PostColorCircles';

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state);

  const { posts: { data, postImageUrl, status }, signup: { role } } = state;

  useEffect(() => {
    dispatch(fetchPosts());
  }, []);

  if(status === "error") {
    return <h1>Error</h1>;
  }

  return !data.length ? <Loader /> : (
    <>
      <Typography variant="h4" align="center">{role}</Typography>
      <Grid container spacing={3} sx={{ p: 1, pb: { xs: 9, sm: 0 } }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={12} md={6} sm={6} xs={22}>
              <PostAmountCard totalPosts={data.length} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={12} md={6} sm={6} xs={22}>
              <PostColorCircles />
            </Grid>
          </Grid>
        </Grid>
        <PostTimeline />
      </Grid>
      { postImageUrl && <PostTimelineDialog  postImageUrl={postImageUrl} /> }
    </>
  );
};

export default Dashboard;
