import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const NotFound: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/404');
  }, []);

  return (
    <Typography variant="h3" style={{ textAlign: 'center' }}>
       NotFound
    </Typography>
  );
};

export default NotFound;
