import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { Box } from '@mui/material';

import { NavItems } from '../NavItems';
import NavItem from '../NavItem';

import { ISwipeableTemporaryDrawerProps } from './types';

import { SNavWrapper } from './styles';

const SwipeableTemporaryDrawer = ({ state, toggleDrawer }: ISwipeableTemporaryDrawerProps): JSX.Element => {
  const navitems = NavItems.items;

  return (
    <SwipeableDrawer
      open={state}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={SNavWrapper}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {navitems.map((navitem) => {
          const submenues = navitem?.childrens;
          const { name, title, ...otherProps } = navitem;

          return <NavItem submenues={submenues} key={name} name={name} title={title} {...otherProps}/>;
        })}
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableTemporaryDrawer;
