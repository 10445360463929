export const SPostContainer = {
  width: { xs: '94.5%', md: 'calc(100vw - 304px)' },
  height: '100%', 
  flexDirection: 'column', 
  alignItems: 'center',
  justifyContent: 'center', 
  display: "flex",
  p: 0,
  paddingBottom: { xs: '20px', md: '60px' },
  mt: { xs: '10px', md: '1px' },
  mx: { xs: 'auto' },
  mb: { xs: '60px', md: '1px' }
};
