import { createHttpClient } from './http/client';
// import cookie from 'react-cookies';

import { SERVER_URL } from './constants';

export const httpClient = createHttpClient({
  baseURL: `${SERVER_URL}`,
  timeout: 300000,
  withCredentials: false,
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    // 'Accept-Language': cookie.load('lgit ang'),
    // 'Access-Control-Allow-Origin': '*',
  },
});
