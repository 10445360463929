import { MarkersNavLink } from "./constants";
import { INavItem } from "../types";

export const navlinks: INavItem = {
  path: `/${MarkersNavLink}`,
  name: 'Markers',
  icon: 'PushPinIcon',
  type: 'collapse',
  title: 'markers',
  childrens: [
    {
      id: 'level-zero',
      name: 'L0',
      path: `/${MarkersNavLink}`,
      param: '0',
    },
    {
      id: 'level-one',
      name: 'L1',
      path: `/${MarkersNavLink}`,
      param: '1',
    },
    {
      id: 'level-two',
      name: 'L2',
      path: `/${MarkersNavLink}`,
      param: '2',
    },
    {
      id: 'level-three',
      name: 'L3',
      path: `/${MarkersNavLink}`,
      param: '3',
    }
  ]
};
