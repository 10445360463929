import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { IColorCircle } from './types';
import { SBox, SColorCircle, SColorCount, SColorTitle } from './style';

export const ColorCircle: FC<IColorCircle> = ({ count, color, title }) => {

  return (
    <Box sx={SBox}>
      <Box 
        sx={SColorCircle}
        bgcolor={color}
      >
        <Typography sx={SColorCount}>
          {count}
        </Typography>
      </Box>
      <Typography sx={SColorTitle}>
        {title}
      </Typography>
    </Box>
  ); };
