import { FC } from 'react';
import { Typography } from '@mui/material';

const Prices: FC = () => {
  return (
    <Typography variant="h4"  style={{ textAlign: 'center' }}>
      Prices
    </Typography>
  );
};

export default Prices;
