import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpClient } from 'api/clients';
import { IMarkers } from 'api/services/markers/types';

export const fetchMarkers = createAsyncThunk<IMarkers[], string>(
  'signup/fetchMarkers',
  async (param) => {
    if (param) {
      const response = await httpClient.get(
        `markers?L=${param}`,
      );
  
      return response.data;
    }
  },
);
