import { IRoute } from 'router/types';
import ViewComponent from 'views/ViewComponent';
import { MarkersPageView } from './constants';
import Markers from 'components/Markers';

export const routes: IRoute = {
  path: `/${MarkersPageView}/*`,
  component: ViewComponent,
  defaultModule: {
    component: Markers,
    module: MarkersPageView,
    index: true,
  },
  modules: [
    {
      path: `*`,
      component: Markers,
    },
  ],
};
