import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Divider, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
  ListSubheader, 
  Typography 
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import NavAccordion from '../NavAccordion';

import { icons } from '../NavItems/constants';
import { INavItemProps } from './types';

import { SActiveButton, SUnactiveButton, SListSubheader, SNavLink, STypography } from '../style';
import { SList } from './styles';

const NavItem: FC<INavItemProps> = ({ name, path, icon, title, submenues }) => {
  
  const { t } = useTranslation();
  
  const pathname = window.location.pathname;
  const Icon = icons[icon];
  return (
    <>
      <List sx={SList} subheader={
        title &&
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={SListSubheader}>
          {t(`sidebar.${title}`)}
        </ListSubheader>
      }>
        <ListItem disablePadding>
          
          {submenues ? (
            <NavAccordion name={name} title={title} Icon={Icon} submenues={submenues}/>
          ) : (
            <NavLink to={path} style={SNavLink}>
              <ListItemButton sx={pathname === path ?  SActiveButton : SUnactiveButton}>
                <ListItemIcon>
                  <Icon/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant={'body1'} color="inherit" sx={STypography}>
                    {t(`sidebar.${title}`)}
                  </Typography>
                } />
              </ListItemButton>
            </NavLink>
          )}
        </ListItem>
      </List>
      {title === 'dashboard' && <Divider />}
    </>
  );
};

export default NavItem;
