import { 
  Box,
  ButtonBase,
  Avatar
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import LogoSection from 'components/LogoSection';
// import SearchSection from 'components/SearchSection';
// import NotificationSection from 'components/NotificationSection/NotificationSection';
import ProfileSection from 'components/ProfileSection/ProfileSection';
  
import { IHeaderProps } from './types';
  
import { 
  SLogoWrapperBox, 
  SLogoBox, 
  SButtonBase, 
  SAvatar, 
  SMenuIcon,
  SEmptyBox
} from './styles';
import { LanguageBar } from 'components/LanguageBar';
  
const HeaderView = ({ toggleDrawer }: IHeaderProps): JSX.Element => {
  return (
    <>
      <Box sx={SLogoWrapperBox}>
        <Box component="span" sx={SLogoBox}>
          <LogoSection />
        </Box>
                  
        <ButtonBase 
          onClick={toggleDrawer(true)}
          sx={SButtonBase}
        >
          <Avatar
            variant="rounded"
            sx={SAvatar}
            color="inherit"
          >
            <MenuIcon style={SMenuIcon} />
          </Avatar>
        </ButtonBase>
      </Box>
  
      {/* <SearchSection /> */}
      <Box sx={SEmptyBox} />
      <Box sx={SEmptyBox} />
  
      <LanguageBar/>
      {/* <NotificationSection /> */}
      <ProfileSection/>
    </>
  );
};
  
export default HeaderView;
