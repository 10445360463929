export const postsSchema = { 
  grade: 1, 
  createdAt: 2, 
  imgUri: 3, 
  latitude: 4, 
  longitude: 5, 
  updatedAt: 6, 
  text: 7, 
  hashTags: 8, 
  place: 9, 
  socials: 10, 
  uid: 11, 
  userUid: 12, 
  deviceUid: 13, 
  deletedAt: 14   
};
