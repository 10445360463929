import { 
  FC, 
  useState, 
  KeyboardEvent,
  MouseEvent
} from 'react';
import { Outlet } from 'react-router-dom';

import { Box, AppBar, Toolbar } from '@mui/material';

import SideBar from 'views/Sidebar';
import HeaderView from 'views/components/Header';
import FooterView from 'views/components/Footer';

import { LayoutBoxStyle, SAppbarWrapper, SLayoutWrapper, SToolbar } from './style';

const Layout: FC = () => {

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const toggleDrawer =
        (open: boolean) =>
          (event: KeyboardEvent | MouseEvent) => {
            if (
              event &&
                    event.type === 'keydown' &&
                    ((event as KeyboardEvent).key === 'Tab' ||
                        (event as KeyboardEvent).key === 'Shift')
            ) {
              return;
            }
            setOpenDrawer(open);
          };

  return (
    <Box sx={SLayoutWrapper}>
      <Box sx={SAppbarWrapper}>
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
        >
          <Toolbar sx={SToolbar}>
            <HeaderView
              toggleDrawer={toggleDrawer}
            />
          </Toolbar>
        </AppBar>

        <SideBar
          state={openDrawer}
          toggleDrawer={toggleDrawer}
        />

        <Box sx={LayoutBoxStyle}>
          <Outlet />
        </Box>
                
      </Box>

      <Box>
        <FooterView/>
      </Box>
    </Box>
  );
};

export default Layout;
