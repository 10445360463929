import { FC } from 'react';
import { Box, Drawer } from '@mui/material';

import { NavItems } from '../NavItems';
import NavItem from '../NavItem';

import { SClippedDrawerWrapper, SClippedDrawer, SBox } from './styles';

const ClippedDrawer: FC = () => {
  const navitems = NavItems.items;

  return (
    <Box sx={SClippedDrawerWrapper}>
      <Drawer
        variant="permanent"
        sx={SClippedDrawer}
      >
        <Box sx={SBox}>
          {navitems.map((navitem) => {
            const submenues = navitem?.childrens;
            const { name, ...otherProps } = navitem;

            return <NavItem submenues={submenues} key={name} name={name} {...otherProps}/>;
          })}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ClippedDrawer;
