import { 
  FC, 
  SyntheticEvent, 
  useEffect, 
  useState 
} from 'react';

import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'features/hooks/reduxHooks';

import { fetchMarkers } from 'api/services/markers/requests';

import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText,  
  Typography 
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Brightness1Icon from '@mui/icons-material/Brightness1';

import { INavAccordion } from './types';
import { SActiveButton, SUnactiveButton, SNavLink, STypography } from '../style';
import { SAccordionDetails, SBigDot, SExpandedIcon, SNavAccordion } from './styles';

const NavAccordion: FC<INavAccordion> = ({ name, title, Icon, submenues }) => {
  
  const { t } = useTranslation();

  const pathname = window.location.pathname;
  const dispatch = useAppDispatch();
  
  const [expanded, setExpanded] = useState<string | false>(false);
  const [level, setLevel] = useState<string>('');

  useEffect(() => {
    dispatch(fetchMarkers(level));
  }, [level]);

  useEffect(() => {
    pathname !== '/markers' && setExpanded(false);
  }, [pathname]);

  const handleChangeLevel = (id: string): void => {
    setLevel(id);
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(isExpanded ? panel : false);
    };
  
  return (
    <Accordion 
      sx={[
        SNavAccordion, 
        { 
          color: expanded ? '#5e35b1e8' : 'grey' 
        }
      ]} 
      expanded={expanded === 'panel1'} 
      onChange={handleChange('panel1')}
    >
      <ListItemText primary={
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={[
              SExpandedIcon,
              { 
                color: expanded ? '#5e35b1e8' : 'grey',
              }
            ]}/>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <ListItemIcon sx={
            { color: expanded ? '#5e35b1e8' : 'grey' }
          }>
            <Icon />
          </ListItemIcon>
          <Typography variant={'body1'} color="inherit" sx={STypography}>
            {t(`sidebar.${title}`)}
          </Typography>
        </AccordionSummary>              
      } />
      
      {submenues.map((navSubitem: any) => {
        const { name, id, path, param } = navSubitem;
        return (
          <AccordionDetails sx={SAccordionDetails} key={ id }>
            <NavLink to={path} style={SNavLink}>
              <ListItemButton 
                onClick={() => handleChangeLevel(param)} 
                sx={level === param && pathname === '/markers' ?  SActiveButton : SUnactiveButton}
              >
                <Brightness1Icon sx={SBigDot}/>
                <ListItemText primary={
                  <Typography variant={'body1'} color="inherit" sx={STypography}>
                    {name}
                  </Typography>
                } />
              </ListItemButton>
            </NavLink>
          </AccordionDetails>
        );
      })}
    </Accordion>
  );
};

export default NavAccordion;
