import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { languages } from './constants';

export const LanguageBar = () => {

  const [language, setLanguage] = useState(Cookies.get('i18next' || languages[0].value));

  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        value={language}
        onChange={handleChange}
      >
        {languages.map((lng) => {
          return (
            <MenuItem  
              key={lng.value}
              value={lng.value}
            >
              {lng.label}
            </MenuItem>
          );
          
        })}
      </Select>
    </FormControl>
  );
};
