import { createSlice } from '@reduxjs/toolkit';
import { fetchPosts } from 'api/services/posts/requests';
import { IPostState } from './types';

const initialState: IPostState = {
  data: [],
  postImageUrl: null,
  status: '',
};

export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setPostImageUrl(state, action) {
      state.postImageUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.status = '';
    });

    builder.addCase(fetchPosts.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    builder.addCase(fetchPosts.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export const { setPostImageUrl } = postSlice.actions;
export default postSlice.reducer;
