import { FC, useEffect } from 'react';
import { Box, Alert } from '@mui/material';

import { fetchPosts } from 'api/services/posts/requests';

import { useAppDispatch, useAppSelector } from 'features/hooks/reduxHooks';
import Loader from 'shared/components/Loader';

import EnhancedTable from './components/EnhancedTable';
import { PostsTableComparator, PostsTableInitializer } from './constants';
import { SPostContainer } from './style';

const Posts: FC = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(state => state.posts);

  const { data, status } = posts;

  useEffect(() => {
    dispatch(fetchPosts());
  }, []);

  if(status === "error") {
    return <Alert severity="error">Some error occured!</Alert>;
  }

  return !data.length ? <Loader /> : (
    <Box sx={SPostContainer}>
      <EnhancedTable 
        data={data} 
        tableTitle={PostsTableInitializer}
        tableHeadComparator={PostsTableComparator}
        tableType={PostsTableInitializer}
      />
    </Box>
  );

};

export default Posts;
