import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISignupState } from './types';

const initialState: ISignupState = {
  login: '',
  role: 'user',
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
    },
    setCurrentRole: (state, action: PayloadAction<string | undefined>) => {
      state.role = action.payload;
    }
  },
});

export const { setLogin, setCurrentRole } =
  signupSlice.actions;

export default signupSlice.reducer;
