export const SNavAccordion = { 
  boxShadow: 'none',
  borderRadius: '10px',
  '&: hover': {
    color: "#5e35b1e8",
    [`& .MuiListItemIcon-root`]: {
      color: "#5e35b1e8"
    },
    [`& .MuiSvgIcon-root`]: {
      color: "#5e35b1e8"
    }
  }
};

export const SExpandedIcon = {
  p: { xs: '0 10px 0 10px', sm: '0 30px 0 30px' }
};

export const SAccordionDetails = { 
  p: 0.4 
};

export const SBigDot = { 
  fontSize: '0.3rem', 
  mr: 2 
};
