import { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { routes } from './routes';
import { TFCs } from './types';

export const renderViewRoutes: TFCs<ReactNode> = () => {
  return routes.map(({ path, component: Comp, ...rest }, index) => {
    return (
      <Route
        key={`${path}${index}`}
        path={`${path}`}
        {...rest}
        element={<Comp path={path} {...rest} />}
      />
    );
  });
};
