import { Box } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
// timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/hooks/reduxHooks';
import { setPostImageUrl } from 'features/postSlice';

import { getComparator, stableSort } from 'components/Posts/PostsAdmin/components/EnhancedTable/utils/sortHandlers';

import { STimelineOppositeContent, STimelineContent, SImageBox } from './styles';

export const PostTimeline = () => {
  const data = useAppSelector(state => state.posts.data);
  const orderby: string = 'createdAt';

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleOpenImage = (postImageUrl: string) => {
    dispatch(setPostImageUrl(postImageUrl));
  };
  
  return (
    <> 
      <Timeline 
        // sx={{
        //   [`& .${timelineOppositeContentClasses.root}`]: {
        //     flex: 0.3,
        //   },
        // }}
      >
        {Array.isArray(data) &&
       stableSort(data, getComparator('desc', orderby))?.slice(0, 5).map((post) => {
         return (
           <TimelineItem key={post.id}>
             <TimelineOppositeContent
               sx={STimelineOppositeContent}
               align='right'
               variant='body2'
               color='text.secondary'
             >
               {post.createdAt && `${new Date(post.createdAt).toLocaleTimeString()} ${new Date(post.createdAt).toLocaleDateString()}`}
             </TimelineOppositeContent>
             <TimelineSeparator>
               <TimelineConnector />
               {post.imgUri && 
                  <Box 
                    onClick={() => handleOpenImage(post.imgUri as string)}
                    sx={SImageBox}
                    component='img'
                    src={post.imgUri}
                  >
                  </Box>}
               <TimelineConnector />
             </TimelineSeparator>
             <TimelineContent sx={STimelineContent}>
               {post.hashTags?.length ? post.hashTags.map((item, i) => 
                 <Typography key={i}> {item} </Typography>) :
                 <Typography> {t('dashboard.timeline.empty')} </Typography>
               }
               <Typography>
                 {post.text?.length ? post.text : t('dashboard.timeline.emptytext')}
               </Typography>
             </TimelineContent>
           </TimelineItem>
         );
       })
        }
      </Timeline>
    </>
  );
};
